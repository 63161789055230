/* Fonts */

@font-face {
  font-family: 'Raleway';
  font-weight: 100;
  src: local('Raleway'),
    url(./theme/fonts/Raleway/Raleway-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway';
  font-weight: 200;
  src: local('Raleway'),
    url(./theme/fonts/Raleway/Raleway-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway';
  font-weight: 300;
  src: local('Raleway'),
    url(./theme/fonts/Raleway/Raleway-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway';
  font-weight: 400;
  src: local('Raleway'),
    url(./theme/fonts/Raleway/Raleway-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway';
  font-weight: 500;
  src: local('Raleway'),
    url(./theme/fonts/Raleway/Raleway-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway';
  font-weight: 600;
  src: local('Raleway'),
    url(./theme/fonts/Raleway/Raleway-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway';
  font-weight: 700;
  src: local('Raleway'),
    url(./theme/fonts/Raleway/Raleway-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway';
  font-weight: 800;
  src: local('Raleway'),
    url(./theme/fonts/Raleway/Raleway-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway';
  font-weight: 900;
  src: local('Raleway'),
    url(./theme/fonts/Raleway/Raleway-Black.ttf) format('truetype');
}

@font-face {
  font-family: font1;
  src: url('./games/KoalouBreathing/KoalouBreathing/assets/fonts/Turds-vppD.ttf');
}

@font-face {
  font-family: font2;
  src: url('./games/KoalouBreathing/KoalouBreathing/assets/fonts/Skyfont-NonCommercial.otf');
}

/* html,
body,
#root,
.App {
  min-height: 100vh;
  background-color: #aed1cd33;
} */

html {
  min-width: 320px;
}

html,
body,
#root,
.App {
  background-color: #eff6f5;
  font-family: Raleway, Arial, sans-serif;
  overflow-x: hidden;
  min-height: 100vh;
}

/* Custom loader */
.loader {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 4px solid #2e666d;
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s linear infinite;
  -webkit-animation: spin 1s linear infinite;
}
.loader--small {
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 4px solid #2e666d;
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s linear infinite;
  -webkit-animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 0.9em;
}

::-webkit-scrollbar-track {
  background: #d5e0e2;
  border-radius: 11px;
}

::-webkit-scrollbar-thumb {
  background-color: #82a3a7; /* color of the scroll thumb */
  border-radius: 11px; /* roundness of the scroll thumb */
  border: 3px solid #d5e0e2; /* creates padding around scroll thumb */
}

/* Todo item transition slide out */
.slide-out {
  animation: slide-out 0.5s backwards;
  -webkit-animation: slide-out 0.5s backwards;
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(100%);
  }
}

/* Hide scrollbar but keep scrolling */
/* For Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Toast notification */

.Toastify__toast--success {
  background: #bedad7 !important;
  border-radius: 16px;
  color: #2e666d;
  /* H4 */
  font-family: 'Raleway';
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}

.Toastify__toast--error {
  background: #f76d7d !important;
  border-radius: 16px;
  color: #fff;
  /* H4 */
  font-family: 'Raleway';
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}

/* Cookie Consent */
.CookieConsent {
  z-index: 1000 !important;
}

/* React tour */
.mask {
  color: #000000;
  opacity: 0.75;
}

[data-tour-elem='badge'] {
  display: none;
}

[data-tour-elem='controls'] {
  display: none !important;
}

/* DatePicker */

.react-datepicker {
  font-family: 'Raleway' !important;
}

.react-datepicker__header {
  background-color: #abc2c5 !important;
}

.react-datepicker__day--selected {
  background-color: #abc2c5 !important;
}

/* Date input native */

/* [type='date']::-webkit-inner-spin-button {
  display: none;
}
[type='date']::-webkit-calendar-picker-indicator {
  display: none;
} */

/* Date placeholder text style */
input[type='date']::-webkit-datetime-edit-text,
input[type='date']::-webkit-datetime-edit-month-field,
input[type='date']::-webkit-datetime-edit-day-field,
input[type='date']::-webkit-datetime-edit-year-field {
  color: #6c757d;
}

/* Date regular text style */
input[type='date'].date-input--has-value::-webkit-datetime-edit-text,
input[type='date'].date-input--has-value::-webkit-datetime-edit-month-field,
input[type='date'].date-input--has-value::-webkit-datetime-edit-day-field,
input[type='date'].date-input--has-value::-webkit-datetime-edit-year-field {
  color: black;
}

/* TODO Thibaut's work react calendar wip UI */

/* TODO responsivness width 100% on mobile */
.react-calendar {
  /* width: 40%; */
  /* max-width: 100%; */
  background: #fdfaf6;
  font-family: 'Raleway';
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  color: #2e666d;
  min-width: 44px; /* TODO why this ???? */
  background: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

abbr[title] {
  text-decoration: none !important;
}

.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  /* font-weight: bold; */
  /* color: black; */
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  border-radius: 100%;
  background: #aed1cd;
  /* color: #2e666d; */
}
.react-calendar__tile:disabled {
  background: none;
  color: black;
  font-weight: bold;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  /* background-color: #e6e6e6; */
}

.react-calendar__tile--now {
  position: relative;
}

.react-calendar__tile--now::after {
  position: absolute;
  content: '';
  width: 6px;
  height: 6px;
  background-color: #f98a97;
  border-radius: 50%;
  bottom: 5px;
  left: 21px;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  text-decoration: underline #f98a97;
}
.react-calendar__tile--hasActive {
  background: #aed1cd;
  color: #2e666d;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #aed1cd;
  color: #2e666d;
}
.react-calendar__tile--active {
  background: #2e666d;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #2e666d;
  color: white;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

/* LEGACY */

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

/*  Visual analogue TODO delete with Mui  */ /* TODO */

.visual-analogue-scale {
  position: relative;
  margin-top: 30px;
  width: 800px;
}

.bar {
  position: absolute;
  width: 100%;
  height: 20px;
  border-radius: 10px;
  background: linear-gradient(270deg, #f76d7d 0%, #fde2e5 100%);
  display: flex;
  align-items: center;
  border: solid 1px #ddd;
  box-sizing: border-box;
  padding: 0 12px;
}

#cursor {
  position: absolute;
  margin-top: 28px;
  margin-left: -12px;
  display: flex;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background-color: white;
  border: 2px solid #eee;
  box-sizing: border-box;
}

.levels .level:nth-child(1) {
  left: 0%;
}

.levels .level:nth-child(2) {
  left: 10%;
}

.levels .level:nth-child(3) {
  left: 20%;
}

.levels .level:nth-child(4) {
  left: 30%;
}

.levels .level:nth-child(5) {
  left: 40%;
}

.levels .level:nth-child(6) {
  left: 50%;
}

.levels .level:nth-child(7) {
  left: 60%;
}

.levels .level:nth-child(8) {
  left: 70%;
}

.levels .level:nth-child(9) {
  left: 80%;
}

.levels .level:nth-child(10) {
  left: 90%;
}

.levels .level:nth-child(11) {
  left: 100%;
}

.levels {
  position: relative;
  width: 100%;
  margin-top: -30px;
  height: 50px;
}
.levels .level {
  transform: translateX(-50%);
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #ccc;
  width: 24px;
}
.levels .level label {
  padding: 0 15px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.levels .level label .number {
  margin-top: 5px;
  height: 25px;
}
.levels .level label:hover {
  cursor: pointer;
}
.levels .level input {
  display: none;
}
.levels .level .line {
  border-left: 1px dashed #eee;
  height: 20px;
}
